<template>
    <div style="height: 95%;position:relative;background: #fff;padding: 20px;border-radius: 15px;">
        <img src="../../assets/images/back.png" alt="" style="float:right;width:40px;cursor:pointer" @click="backHotel">
        <div style="font-size:20px;margin-bottom:20px;">{{hotelName}}</div>
        <!--搜索区域-->
        <div class="header">
            <div style="">
                手机号：
                <el-input v-model="userName" size="medium" placeholder="请输入内容" @keyup.enter.native="getUserList" @submit.native.prevent></el-input>
            </div>
            <div style="">
                账号状态：
                <el-select v-model="status" placeholder="请选择" @change="getUserList">
                    <el-option
                            v-for="item in statusSel"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div style="margin:0 10px;">
                人员类型：
                <el-select v-model="userType" placeholder="请选择" @change="getUserList">
                    <el-option
                            v-for="item in userTypeSel"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="getUserList()">搜索</el-button>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addUserfun">新增</el-button>
        </div>
        <div class="wrapper">
            <el-table :data="userData" style="width: 100%"  v-loading="loading">
                <el-table-column type="index" label="序号" width="50"></el-table-column>

                <el-table-column prop="userType" label="人员类型" width="200">
                    <template scope="scope">
                        <span v-if="scope.row.userType===1">房客</span>
                        <span v-else-if="scope.row.userType===2">施工人员</span>
                        <span v-else-if="scope.row.userType===3">系统管理员</span>
                        <span v-else-if="scope.row.userType===4">酒店管理员</span>
                    </template>
                </el-table-column>
                <el-table-column prop="hotelName" label="所属酒店" width="200"></el-table-column>
                <el-table-column prop="userName" label="手机号" width="200"></el-table-column>
                <el-table-column prop="nickName" label="姓名" width="200"></el-table-column>
                <el-table-column prop="createTime" label="注册时间" width="200"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="200"></el-table-column>
                <el-table-column prop="status" label="账号状态" width="100">
                    <template scope="scope">
                        <span style="border:1px solid #666;color:#666;background:#ddd;padding: 0 5px;border-radius: 3px;"  v-if="scope.row.status===0">禁用</span>
                        <span style="border:1px solid #276EF1;color:#276EF1;background:#E1EBFF;padding: 0 5px;border-radius: 3px;" v-else-if="scope.row.status===1">正常</span>
                        <span style="border:1px solid #D90000;color:#D90000;background:#FFF1F1;padding: 0 5px;border-radius: 3px;" v-else-if="scope.row.status===2">锁定</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="操作" >
                    <template slot-scope="scope">
                        <div v-if="userTypeInfo==4&&scope.row.userType==4" class="operate">
                            <span  style="cursor : pointer;color:#ccc;">编辑</span>
                            <span  style="cursor : pointer;color:#ccc;">删除</span>
                        </div>
                        <div v-else class="operate">
                            <span @click="handleEdit(scope)" style="cursor : pointer;">编辑</span>
                            <span @click="handleDelete(scope)" style="cursor : pointer;">删除</span>
                        </div>

                    </template>
                </el-table-column>
            </el-table>
            <!--分页-->
            <Mypage :total="total" :pageSize="limit" @changePage="changePage"/>
        </div>



        <!--增加编辑对话框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="25%" :close-on-click-modal="false" :before-close="resetForm">
            <el-form :model="userForm" status-icon :rules="rules" ref="userForm" class="demo-ruleForm">
                <el-form-item label="人员类型" prop="userType">
                    <el-select v-model="userForm.userType" placeholder="请选择" v-bind:disabled="title=='编辑'" @change="selUserType" style="width:230px;">
                        <el-option label="房客" value="1"></el-option>
                        <el-option label="施工人员" value="2"></el-option>
                        <!--<el-option label="系统管理员" value="3"></el-option>-->
                        <el-option v-if="userTypeInfo==3" label="酒店管理员" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属酒店">
                    <el-input type="text" v-model="hotelName" autocomplete="off" disabled style="width:230px;margin:0;"></el-input>
                </el-form-item>
                <el-form-item label="入住房间" prop="roomId" v-if="userForm.userType==1" >
                    <el-select v-model="userForm.roomId" placeholder="请选择" style="width:230px;">
                        <el-option v-for="(item,index) in roomData" :key="index"
                                   :label="item.roomNumber"
                                   :value="item.roomId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" prop="userName">
                    <el-input type="text" v-model="userForm.userName" autocomplete="off" v-bind:disabled="title=='编辑'" style="width:230px;margin:0;"></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="nickName" >
                    <el-input type="text" v-model="userForm.nickName" autocomplete="off" style="width:230px;margin:0;"></el-input>
                </el-form-item>

                <el-form-item label="账号状态" prop="status">
                    <!--<el-radio-group v-model="userForm.status">-->
                    <el-radio v-model="userForm.status" :label="1">正常</el-radio>
                    <el-radio v-model="userForm.status" :label="0">禁用</el-radio>
                    <!--</el-radio-group>-->
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitUserMsg('userForm')">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
    import Mypage from '../../components/MyPagination.vue'
    import {userList,addUser,editUser,userDetail,delUser,roomList} from './user'

    export default {
        components: {
            Mypage
        },
        name: "profile",
        // props: ['hotelId'],
        data() {
            return {
                hotelName:this.$route.query.hotelName,
                hotelId:this.$route.query.hotelId,
                userTypeInfo:this.$cookies.get("userType"),
                loading:true,
                dialogVisible: false, //打开新增模态框
                title:'新增',
                statusSel: [
                    {
                        value: '',
                        label: '请选择'
                    },{
                    value: '0',
                    label: '禁用'
                }, {
                    value: '1',
                    label: '正常'
                }],
                status:'',
                userTypeSel:[
                    {
                        value: '',
                        label: '请选择'
                    },{
                    value: '1',
                    label: '房客'
                },{
                    value: '2',
                    label: '施工人员'
                },
                //     {
                //     value: '3',
                //     label: '系统管理员'
                // },
                    {
                    value: '4',
                    label: '酒店管理员'
                }],
                hotelData:[], //酒店列表
                roomData:[],//房间列表
                userType:'',
                userName:'',
                createTime:'',
                total:10,
                limit: 12,
                page: 1,
                userData: [],
                userId:'',
                //新增用户模态框数据
                userForm: {
                    userName: '', //用户名
                    nickName:'', //姓名
                    userType: '', //人员类型
                    hotelId:this.$route.query.hotelId, //所属酒店ID
                    roomId:'',//房间ID
                    hotelName:'',// 所属酒店名称
                    status: 1, //账号状态
                },
                rules: {
                    userName: [
                        {required: true, message: "请输入手机号", pattern: /^1[3456789]\d{9}$/, trigger: "manul",},
                    ],
                    nickName: [
                        {required: true, message: "请输入姓名", trigger: "manul"},
                    ],
                    userType: [
                        {required: true, message: "请选择人员类型", trigger: "change",},
                    ],
                    status: [
                        {required: true, message: "请选择账号状态", trigger: "change"},
                    ],
                },
                isManger:false,//是否创建了酒店管理员
            }
        },
        methods: {
            //表单验证重置
            resetForm(){
                this.dialogVisible=false
                this.$refs.userForm.resetFields()
            },
            //返回酒店列表（HotelMange页面）
            backHotel(){
                this.$router.push({
                    path:'./HotelMange',
                })
            },
            //    查询用户列表
            getUserList() {
                userList(this.hotelId,this.status, this.userName, this.userType, this.limit, this.page,this.$cookies.get("access_token")).then((result) => {
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            this.isManger=false
                            for(var i=0;i<result["data"]["data"]["records"].length;i++){
                                if(result["data"]["data"]["records"][i].userType==4){
                                    this.isManger=true
                                }
                            }

                            this.userData = result["data"]["data"]["records"]
                            this.total = parseInt(result["data"]["data"]["total"])
                        } else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                        this.loading=false
                    }
                })
            },
            //人员类型是‘房客’时查询所有酒店
            selUserType(val){
                console.log('vale0',val)
                if(val==1){
                    this.rules= {
                        userName: [
                            {required: true, message: "请输入手机号", pattern: /^1[3456789]\d{9}$/, trigger: "manul",},
                        ],
                            nickName: [
                            {required: true, message: "请输入姓名", trigger: "manul"},
                        ],
                            userType: [
                            {required: true, message: "请选择人员类型", trigger: "change",},
                        ],
                            roomId: [
                            {required: true, message: "请选择入住房间", trigger: "change",},
                        ],
                            status: [
                            {required: true, message: "请选择账号状态", trigger: "change"},
                        ],
                    }
                    roomList(this.hotelId,this.$cookies.get("access_token")).then((result) => {
                        if (result["data"].code == 0) {
                            this.roomData=result["data"]["data"]
                            console.log("roomid",this.roomData)
                            if(this.title=='新增'){
                                if(result["data"]["data"].length==0){
                                    this.$confirm('请先新增房间！', '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                    }).then(() => {
                                        this.dialogVisible=false
                                    })
                                    this.userForm.roomId=''
                                }else {
                                    this.userForm.roomId=result["data"]["data"][0].roomId
                                }
                            }
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    })
                }else {
                    this.rules= {
                        userName: [
                            {required: true, message: "请输入手机号", pattern: /^1[3456789]\d{9}$/, trigger: "manul",},
                        ],
                        nickName: [
                            {required: true, message: "请输入姓名", trigger: "manul"},
                        ],
                        userType: [
                            {required: true, message: "请选择人员类型", trigger: "change",},
                        ],
                        status: [
                            {required: true, message: "请选择账号状态", trigger: "change"},
                        ],
                    }
                }
            },
            addUserfun(){
                this.dialogVisible = true;
                this.title='新增'
                this.userForm= {
                    userName: '', //用户名
                    nickName:'', //姓名
                    userType: '', //人员类型
                    hotelId:this.hotelId,
                    roomId:'',//房间ID
                    status: 1, //账号状态
                }
            },
            //    编辑
            handleEdit(scope) {
                console.log('scope',scope.row)
                this.dialogVisible = true
                this.title='编辑'

                userDetail(scope.row.userId,this.hotelId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        result['data']['data'].userType=result['data']['data'].userType.toString()
                        console.log("roomid",typeof result['data']['data'].roomId)
                        this.userForm=result["data"]["data"]
                        this.selUserType(result["data"]["data"].userType)
                        // this.selHotel(result["data"]["data"].hotelId)
                    }
                })
                this.userId=scope.row.userId
            },
            //    删除
            handleDelete(scope) {
                this.$confirm('确认删除该用户吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading=true
                    delUser(scope.row.userId,this.hotelId,this.$cookies.get("access_token")).then((result)=>{
                        if (result["data"].code == 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getUserList()
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                            this.loading=false
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 提交酒店模态框数据
            submitUserMsg(formName) {
                // this.loading=true
                if(this.title=='新增'){
                    console.log('mange',this.isManger)
                    if(this.isManger==false&&this.userForm.userType!='4'){
                        this.$message({
                            message: '请先添加酒店管理员！',
                            type: 'error'
                        });
                    }else{
                        this.$refs[formName].validate((valid) => {
                            if (valid) {
                                console.log('form', this.userForm)
                                addUser(this.userForm, this.$cookies.get("access_token")).then((result) => {
                                    if (result.data.code == 0) {
                                        this.$message({
                                            message: '添加成功！',
                                            type: 'success'
                                        });
                                        this.dialogVisible = false
                                        this.getUserList();
                                    } else {
                                        this.$message({
                                            message: result["data"].message,
                                            type: 'error'
                                        });
                                        this.loading=false
                                    }

                                }).catch(() => {
                                    this.$message({
                                        message: '请求失败！',
                                        type: 'success'
                                    });
                                    this.dialogVisible = false
                                    this.loading=false
                                })
                            }
                        })
                    }
                }else if(this.title=='编辑'){
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            console.log('form', this.userForm)
                            editUser(this.userForm,this.$cookies.get("access_token")).then((result)=>{
                                console.log('msg',result)
                                if(result["data"].code==0){
                                    this.$message({
                                        message: '修改成功！',
                                        type: 'success'
                                    });

                                    this.getUserList();
                                }else {
                                    this.$message({
                                        message: result["data"].message,
                                        type: 'error'
                                    });
                                    this.loading=false
                                }
                                this.dialogVisible = false
                            }).catch(()=>{
                                this.$message({
                                    message: '请求失败！',
                                    type: 'error'
                                });
                                this.dialogVisible = false
                                this.loading=false
                            })
                        }
                    })
                }

            },
            //    分页
            changePage(num){
                this.page=num;
                this.getUserList()
            }


        },
        created() {  /*生命周期函数*/
            this.getUserList();
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
    }

    .el-input {
        width: 200px;
        margin: 0 10px;
    }

    .el-button {
        padding: 10px 20px;
    }

    .wrapper {
        margin-top: 20px;
        text-align: center;
    }

    .operate span {
        color: #276EF1;
        margin: 0 5px;
    }

    ::v-deep .el-table th, ::v-deep .el-table td {
        text-align: center !important;
    }

    ::v-deep .el-form-item__label {
        width: 100px
    }
    ::v-deep .el-dialog__header {
        border-bottom:1px solid #ddd;
    }

</style>
