<template>
    <div style="height:100%;background:#F0F3FA">
        <el-container style="height:100%;" >
            <el-header class="nav" style="height:auto;">
        <!--<div class="nav" style="height:20%">-->
                <img src="../../assets/images/logo.png" alt="" style="float:left;width:65px;margin-top:5px;">
                <p style="font-size:26px;margin-left:10px;color:#4569ED">智慧轻居运维系统</p>


                <!--<el-select v-model="betaType" placeholder="请选择"  class="betatype" @change="changeType">-->
                <!--<el-option value="酒店" label="智能酒店版">智能酒店版</el-option>-->
                <!--<el-option value="家居" label="智能家居版">智能家居版</el-option>-->
            <!--</el-select>-->

            <!--<div style="float:right;cursor:pointer" @click="checkout">-->
                <!--<i class="iconfont icon-yonghu"></i>-->
                <!--退出登录-->
            <!--</div>-->
                <el-popover style="float:right;cursor:pointer;" placement="bottom" width="240" trigger="click">
                    <div style="padding:0 10px;text-align: left;">
                        <p style="margin:5px">用户角色：
                            <span v-if="userType==1">房客</span>
                            <span v-if="userType==2">施工人员</span>
                            <span v-if="userType==3">超级管理员</span>
                            <span v-if="userType==4">酒店管理员</span>
                         </p>
                        <p style="margin:5px">登录账号：
                            <span>{{mobile}}</span>
                        </p>
                        <p style="margin:5px">用户昵称：
                           <span> {{nickName}}</span>
                        </p>
                        <el-button type="primary"  style="float:right;padding:7px;" @click="checkout">退出登录</el-button>
                    </div>
                    <el-button slot="reference" style="line-height:34px;padding: 0;border: none;margin-top:5px;"><img src="../../assets/images/user.png" style="width:30px;float:left;margin-right:5px;" alt=""> 账号管理</el-button>
                </el-popover>
                <p style="float:right;padding-right:20px;">v1.4.8</p>
        <!--</div>-->
            </el-header>

        <el-container style="height:90%;">
        <!--<div class="layout">-->
            <!--导航-->
            <el-aside style="width:200px;">
                <!--<MyMenu/>-->
                <div style="height:100%;background:#fff;" class="leftMenu">
                    <el-menu :default-active="defaultActive" @select="handleSelect" mode="horizontal" class="el-menu-demo"  router style="float:left;margin-left:20px;">
                        <!--酒店版-->
                        <el-menu-item index="/" v-show="betaType=='酒店'">
                            <i class="iconfont icon-tubiao"></i>
                            <span slot="title" style="margin-left:10px;">数据统计</span>
                        </el-menu-item>
                        <el-menu-item index="/HotelMange" v-show="betaType=='酒店'">
                            <i class="iconfont icon-jiudian"></i>
                            <span slot="title" style="margin-left:10px;">酒店管理</span>
                        </el-menu-item>
                        <!--<el-menu-item index="/User" v-show="betaType=='酒店'">-->
                        <!--<i class="iconfont icon-renyuanguanli"></i>-->
                        <!--<span slot="title" style="margin-left:10px;">人员管理</span>-->
                        <!--</el-menu-item>-->
                        <!--<el-menu-item index="/Operation" v-show="betaType=='酒店'">-->
                        <!--<i class="iconfont icon-yunwei"></i>-->
                        <!--<span slot="title" style="margin-left:10px;">设备运维</span>-->
                        <!--</el-menu-item>-->
                        <!--智能家居版-->
                        <el-menu-item index="/SmartHomeCount" v-show="betaType=='家居'">
                            <i class="iconfont icon-tubiao"></i>
                            <span slot="title" style="margin-left:10px;">数据统计</span>
                        </el-menu-item>
                        <el-menu-item index="/SmartHomeProject" v-show="betaType=='家居'">
                            <i class="iconfont icon-xiangmu"></i>
                            <span slot="title" style="margin-left:10px;">项目管理</span>
                        </el-menu-item>
                        <el-menu-item index="/SmartHomeUser" v-show="betaType=='家居'">
                            <i class="iconfont icon-renyuanguanli"></i>
                            <span slot="title" style="margin-left:10px;">人员管理</span>
                        </el-menu-item>
                        <el-menu-item index="/SmartHomeOperation" v-show="betaType=='家居'">
                            <i class="iconfont icon-yunwei"></i>
                            <span slot="title" style="margin-left:10px;">设备运维</span>
                        </el-menu-item>
                    </el-menu>
                </div>
            </el-aside>

            <!--右侧内容-->
            <el-main>
                <Content/>
            </el-main>
        <!--</div>-->
        </el-container>
        </el-container>
    </div>
</template>

<script>
    // import MyMenu from './myMenu.vue'
    import Content from  './Content.vue'
    import { checkOut } from './index.js'
    export default {
        components: {Content},
        name: "index",
        data() {
            return {
                userType:this.$cookies.get("userType"),
                nickName:this.$cookies.get("nickName"),
                mobile:this.$cookies.get("mobile"),
                betaType: this.$cookies.get("betaType"),
                defaultActive: '/'
            }
        },
        mounted() {
            this.defaultActive = sessionStorage.getItem('keyPath') || '/';
            console.log('nick',this.nickName)
        },
        // watch: {
        //         activeIndex(newValue){
        //             if (newValur != this.$route.path) {
        //                 this.defaultActive = this.$route.path;
        //             }
        //         }
        // },
        methods:{
            handleSelect(keyPath) {
                sessionStorage.setItem('keyPath', keyPath);
            },
            checkout() {
                checkOut(this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.$message({
                            message: '退出成功！',
                            type: 'success'
                        });
                        this.$cookies.set("access_token",'')
                        this.$router.push({
                            path:'./login',
                        })
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
        //    切换版本
            changeType(e){
                console.log('e',e)
                if(e=='酒店'){
                    this.$cookies.set("betaType", "酒店")
                    this.defaultActive='/'
                    this.$router.push({
                        path:'/',
                    })
                }else {
                    this.$cookies.set("betaType", "家居")
                    this.defaultActive='/SmartHomeCount'
                    this.$router.push({
                        path:'/SmartHomeCount',
                    })
                }
            }
        },
        created:function(){

            // this.$router.push({path:'/'})
        },
    }
</script>

<style scoped>
    ::v-deep .el-button--primary {
        background: #4569ED;
        border-color:#4569ED;
    }
    .nav {
        padding:10px 20px;
        width:100%;
        overflow:hidden;
        box-shadow:0 15px 10px -20px #999;
        background:#fff;
    }
    .nav p {
        float:left;
        line-height: 40px;
    }
    .layout {
        display: flex;

    }
    .menu {
        width:200px;
        height: 500px;
        background:#999;
    }
    .content {
        flex:1;
    }
    ::v-deep .betatype .el-input__inner {
        border:none !important;
        width:120px;
    }
    .el-menu {
        border:none;
    }
    ::v-deep .leftMenu .el-menu--horizontal>.el-menu-item {
        height: 40px;
        line-height: 40px;
        color:#000;
        margin:10px 0;
    }
    ::v-deep .leftMenu .el-menu--horizontal>.el-menu-item.is-active {
        border-bottom:none;
        background:#4569ED;
        color:#fff;
        border-radius:30px;
        height: 40px;
        line-height: 40px;
    }
</style>
