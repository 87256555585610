import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout/index.vue'
import Count from '../views/count/count.vue'
import HotelMange from '../views/HotelMange/HotelMange.vue'
import Login from '../views/Login/Login.vue'

const User = ()=>import('../views/User/User.vue')
const Operation = ()=>import('../views/Operation/Operation.vue')
// const Room = ()=>import('../views/Room/Room.vue')
//智能家居版
const SmartHomeCount = ()=>import('../views/smartHome/count/count.vue')
const SmartHomeProject = ()=>import('../views/smartHome/project/project.vue')
const SmartHomeOperation = ()=>import('../views/smartHome/Operation/Operation.vue')
const SmartHomeUser = ()=>import('../views/smartHome/User/User.vue')
// const Manage = ()=>import('../views/Room/manage.vue')
// const Mould = ()=>import('../views/Room/mould.vue')
Vue.use(VueRouter)

const routes = [
    {
        path:'',
        component:Layout,
        //路由元信息
        meta:{
          isLogin:true
        },
        children:[
            {
                path:"/",
                name:'count',
                component:Count
            },{
                path:"/HotelMange",
                name:'HotelMange',
                component:HotelMange
            },{
                path:"/User",
                name:'User',
                component:User
            },
            {
                path: "/manage",
                name: "manage",
                component: () =>
                    import("../views/Room/manage.vue")
            },
            {
                path: "/mould",
                name: "mould",
                component: () =>
                    import("../views/Room/mouldList.vue")
            },
            {
                path: "/mouldEdit",
                name: "mouldEdit",
                component: () =>
                    import("../views/Room/mouldEdit.vue")
            },
            {
                path: "/mouldEditXiaodu",
                name: "mouldEditXiaodu",
                component: () =>
                    import("../views/Room/mouldEditXiaodu.vue")
            },
            {
                path: "/mouldEditNone",
                name: "mouldEditNone",
                component: () =>
                    import("../views/Room/mouldEditNone.vue")

            },{
                path:"/Operation",
                name:'Operation',
                component:Operation
            },{
                path:"/SmartHomeCount",
                name:'SmartHomeCount',
                component:SmartHomeCount
            },{
                path:"/SmartHomeProject",
                name:'SmartHomeProject',
                component:SmartHomeProject
            },{
                path:"/SmartHomeOperation",
                name:'SmartHomeOperation',
                component:SmartHomeOperation
            },{
                path:"/SmartHomeUser",
                name:'SmartHomeUser',
                component:SmartHomeUser
            },
        ]
    },
    {
        path:'/login',
        name:'Login',
        component:Login
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//路由拦截------判断是否登录
router.beforeEach((to,from,next)=>{
    console.log('to-----',to)
    const token = Vue.prototype.$cookies.get('access_token');
    console.log('tken',token)

    //判断是否需要登录
    if (!token && to.path !== '/login') {
        next('/login');
    }else { //不需要登录
        next()
    }
})


export default router
