<template>
    <div style="height: 95%;position:relative;background: #fff; padding: 20px;border-radius: 15px;">
        <div style="font-size:20px;margin-bottom:20px;">{{hotelName}}</div>
        <!--搜索区域-->
        <div class="header">
            <div style="">
                房型名称：
                <el-input v-model="templateName" size="medium" placeholder="请输入内容" @keyup.enter.native="GetTemplateList" @submit.native.prevent></el-input>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="GetTemplateList()">搜索</el-button>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addTemplatefun">新增</el-button>
        </div>
        <div class="wrapper">
            <el-table :data="tableData" style="width: 100%" v-loading="loading">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="templateName" label="房型名称"></el-table-column>
                <el-table-column prop="status" label="状态">
                    <template scope="scope">
                        <span v-if="scope.row.bindCount===0">未绑定</span>
                        <span v-else-if="scope.row.bindCount>0">已绑定</span>
                    </template>
                </el-table-column>
                <el-table-column prop="voiceType" label="语音助手">
                    <template scope="scope">
                        <span v-if="scope.row.voiceType===0">无</span>
                        <span v-else-if="scope.row.voiceType===1">离线语音助手</span>
                        <span v-else-if="scope.row.voiceType===2">小度大金刚</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间"></el-table-column>
                <el-table-column prop="address" label="操作">
                    <template slot-scope="scope">
                        <div class="operate">
                            <span @click="handleEdit(scope)" style="cursor : pointer;">配置</span>
                            <span @click="editName(scope)" style="cursor : pointer;">编辑</span>
                            <span v-if="scope.row.voiceType===1" @click="codeMange(scope.row.hotelId)" style="cursor : pointer;">码库管理</span>
                            <span @click="handleDelete(scope)" style="cursor : pointer;">删除</span>
                            <span v-if="scope.row.voiceType===1" @click="downloadFile(scope)" style="cursor : pointer;">下载</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!--分页-->
            <Mypage :total="total" :pageSize="limit" @changePage="changePage"/>
        </div>



        <!--增加编辑对话框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="25%" :close-on-click-modal="false" :before-close="resetForm">
            <el-form :model="templateForm" status-icon :rules="rules" ref="templateForm" class="demo-ruleForm">
                <el-form-item label="房型名称" prop="templateName">
                    <el-input type="text" v-model="templateForm.templateName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="语音助手" prop="voiceType" v-if="title=='新增'">
                    <el-radio v-model="templateForm.voiceType" :label="0" @input="changeVoice">无</el-radio>
                    <el-radio v-model="templateForm.voiceType" :label="1" @input="changeVoice">离线语音助手</el-radio>
                    <el-radio v-model="templateForm.voiceType" :label="2" @input="changeVoice">小度大金刚</el-radio>
                </el-form-item>
                <el-form-item label="项目ID" prop="shopId" v-if="templateForm.voiceType===2">
                    <el-input type="text" v-model="templateForm.shopId" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input type="text" v-model="templateForm.remarks" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitTemplateMsg('templateForm')">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--码库管理对话框-->
        <el-dialog title="码库管理" :visible.sync="codedialogVisible" width="30%" :close-on-click-modal="false">
            <el-form :model="codeForm" status-icon  ref="codeForm" class="demo-ruleForm">
                <el-form-item label="产品类型" prop="productType">
                    <el-select v-model="codeForm.productType" placeholder="请选择"  style="width:230px;" @change="selInfraredDevice()">
                        <el-option label="电视" value="7002"></el-option>
                        <el-option label="风扇" value="7008"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item  style="margin-left:20px;" :label="item.action" v-for="(item,index) in codeForm.irActionCodeList" v-bind:key="item.action" :prop="`irActionCodeList[${index}].irCode`" :rules="irActionCodeListRules.irCode">

                    <el-input type="text" v-model="item.irCode" autocomplete="off"  style="width:300px;" maxlength="160"
                              onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"   oninput="this.value = this.value.replace(/[^0-9A-F]/g,'')" ></el-input>
                </el-form-item>

                <el-form-item style="text-align: right">
                    <el-button @click="codedialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitCodeMsg('codeForm')">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import Mypage from '../../components/MyPagination.vue'
    import {TemplateList,addTemplate,delRoom,updateTemplate,codeList,addCode} from './mould'
    export default {
        components: {
            Mypage
        },
        name: "mouldList",
        // props: ['hotelId'],
        data() {
            return {
                hotelId:this.$cookies.get("hotelId"),
                hotelName:this.$cookies.get("hotelName"),
                dialogVisible: false, //打开新增模态框
                codedialogVisible:false, //码库管理模态框
                loading:true,
                title:'新增',
                templateName: '',
                total:10,
                limit: 10,
                page: 1,
                tableData: [],
                templateForm: {
                    templateName: '', //房型名称
                    voiceType:0,  //语音助手
                    shopId:'',   //小度项目ID
                    remarks:'',// 备注
                    templateId:''
                },
                rules: {
                    templateName: [
                        {required: true, message: "请输入房型名称", trigger: "blur",},
                    ],
                    voiceType: [
                        {required: true, message: "", trigger: "blur",},
                    ],
                },
                codeForm:{ //码库form
                    codeId:'',
                    hotelId:'',
                    productType:'7002',
                    irActionCodeList:[//红外码列表
                        {
                            action:'电源',
                            irCode:''
                        },
                        {
                            action:'音量+',
                            irCode:''
                        },
                        {
                            action:'音量-',
                            irCode:''
                        },
                        {
                            action:'静音',
                            irCode:''
                        },
                        {
                            action:'频道+',
                            irCode:''
                        },
                        {
                            action:'频道-',
                            irCode:''
                        },
                    ],
                },
                irActionCodeListRules:{
                    irCode:[
                        {required: false, message: '请输入正确的十六进制数', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            //表单验证重置
            resetForm(){
                this.dialogVisible=false
                this.$refs.templateForm.resetFields()
                this.codedialogVisible=false
                this.$refs.codeForm.resetFields()
            },
            //    查询房型列表
            GetTemplateList() {
                TemplateList(this.hotelId, this.templateName, this.limit, this.page,this.$cookies.get("access_token")).then((result) => {
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            this.tableData = result["data"]["data"]["records"]
                            this.total = parseInt(result["data"]["data"]["total"])
                        } else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                        this.loading=false
                    }
                })
            },
            //码库管理
            //码库管理  切换红外设备
            selInfraredDevice(){
                console.log('e',this.codeForm.productType)
                this.getCodeList()

            },
            codeMange(hotelId){
                console.log('hotel',hotelId)
                this.codedialogVisible=true
                this.codeForm.hotelId=hotelId
                this.getCodeList()
            },
            //获取红外码库列表
            getCodeList(){
                codeList(this.codeForm.hotelId,this.codeForm.productType, this.$cookies.get("access_token")).then((result) => {
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            if(result["data"]["data"].irActionCodeList.length!=0){
                                this.codeForm.codeId = result["data"]["data"].codeId
                                this.codeForm.hotelId = result["data"]["data"].hotelId
                                this.codeForm.irActionCodeList = result["data"]["data"].irActionCodeList
                                this.codeForm.productType= result["data"]["data"].productType.toString()
                            }else {
                                if(this.codeForm.productType==7002){
                                    this.codeForm.irActionCodeList=[//红外码列表
                                        {
                                            action:'电源',
                                            irCode:''
                                        },
                                        {
                                            action:'音量+',
                                            irCode:''
                                        },
                                        {
                                            action:'音量-',
                                            irCode:''
                                        },
                                        {
                                            action:'静音',
                                            irCode:''
                                        },
                                        {
                                            action:'频道+',
                                            irCode:''
                                        },
                                        {
                                            action:'频道-',
                                            irCode:''
                                        },
                                    ]
                                }else if(this.codeForm.productType==7008){
                                    this.codeForm.irActionCodeList=[//红外码列表
                                        {
                                            action:'电源',
                                            irCode:''
                                        },
                                        {
                                            action:'换挡',
                                            irCode:''
                                        },
                                        {
                                            action:'摇头',
                                            irCode:''
                                        },
                                        {
                                            action:'定时',
                                            irCode:''
                                        },
                                    ]
                                }

                            }
                            console.log('codeform',this.codeForm)
                        } else {
                            this.$message({
                                message: result.data.message,
                                type: 'error'
                            });
                        }
                    }
                })
            },
            //提交新增编辑码库
            submitCodeMsg(formName){
                console.log('form',this.codeForm)
                var isEven=true //红外码是否都为双数
                for(var i=0;i<this.codeForm.irActionCodeList.length;i++){
                    console.log('i',this.codeForm.irActionCodeList[i].irCode.length%2==0)
                    if(this.codeForm.irActionCodeList[i].irCode.length%2!=0){
                        this.$message({
                            message: '红外码长度必须是双数！',
                            type: 'error'
                        });
                        isEven=false
                        i=this.codeForm.irActionCodeList.length
                    }
                }
                if(isEven){ //红外码长度都为双数
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            console.log('form',this.codeForm)
                            this.loading=true
                            addCode(this.codeForm,this.$cookies.get("access_token")).then((result)=>{
                                if(result.data.code==0){
                                    this.$message({
                                        message: '编辑成功！',
                                        type: 'success'
                                    });
                                    this.codedialogVisible = false
                                }else {
                                    this.$message({
                                        message: result.data.message,
                                        type: 'error'
                                    });
                                    this.loading=false
                                }
                            }).catch(()=>{
                                this.$message({
                                    message: '请求失败！',
                                    type: 'success'
                                });
                                this.codedialogVisible = false
                                this.loading=false
                            })
                        }
                    })
                }
            },
            //新增
            addTemplatefun(){
                this.dialogVisible = true;
                this.title='新增'
                this.templateForm= {
                    templateName: '', //房型名称
                    voiceType:0,  //语音助手
                    shopId:'',   //小度项目ID
                    remarks:'',// 备注
                    templateId:''
                }
            },
            //修改语音助手
            changeVoice(value){
                console.log('value',value)
                if(value==2){
                    this.rules= {
                        templateName: [
                            {required: true, message: "请输入房型名称", trigger: "blur",},
                        ],
                        voiceType: [
                            {required: true, message: "", trigger: "blur",},
                        ],
                        shopId:[
                            {required: true, message: "请输入项目ID", trigger: "blur",},
                        ]
                    }
                }else {
                    this.rules={
                        templateName: [
                            {required: true, message: "请输入房型名称", trigger: "blur",},
                        ],
                        voiceType: [
                            {required: true, message: "", trigger: "blur",},
                        ],
                    }
                }
            },
            //编辑
            editName(scope){
                this.dialogVisible = true;
                this.title='编辑'
                this.templateForm.templateName=scope.row.templateName
                this.templateForm.voiceType=scope.row.voiceType
                this.templateForm.shopId=scope.row.shopId
                this.templateForm.remarks=scope.row.remarks
                this.templateForm.templateId=scope.row.templateId

            },
            //    配置
            handleEdit(scope) {
                if(scope.row.voiceType===0){ //无
                    this.$router.push({
                        path:'./mouldEditNone',  //无语音助手
                        query:{
                            templateId:scope.row.templateId,
                            voiceType:scope.row.voiceType,
                        }
                    })
                }else if(scope.row.voiceType===1){ //小娜
                    this.$router.push({
                        path:'./mouldEdit', // 小娜
                        query:{
                            templateId:scope.row.templateId,
                            voiceType:scope.row.voiceType,
                        }
                    })
                }else if(scope.row.voiceType===2){   //小度
                    this.$router.push({
                        path:'./mouldEditXiaodu',  //小度
                        query:{
                            templateId:scope.row.templateId,
                            voiceType:scope.row.voiceType,
                        }
                    })
                }

            },
            //    删除
            handleDelete(scope) {
                this.$confirm('确认删除该房型吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading=true
                    delRoom(scope.row.templateId,this.$cookies.get("access_token")).then((result)=>{
                        if (result["data"].code == 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.GetTemplateList()
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                            this.loading=false
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //下载
            downloadFile(scope){
                this.$download.sufile("/hotel/template/exportVoiceTemplate?templateId="+scope.row.templateId,this.$cookies.get("access_token"));
            },

            // 提交模态框数据
            submitTemplateMsg(formName) {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.loading=true
                            if(this.title=='新增'){
                                addTemplate(this.hotelId,this.templateForm.templateName,this.templateForm.voiceType,this.templateForm.shopId,this.templateForm.remarks,this.$cookies.get("access_token")).then((result)=>{
                                    if(result.data.code==0){
                                        this.$message({
                                            message: '添加成功！',
                                            type: 'success'
                                        });
                                        this.dialogVisible = false
                                        this.GetTemplateList();
                                    }else {
                                        this.$message({
                                            message: result.data.message,
                                            type: 'error'
                                        });
                                        this.loading=false
                                    }

                                }).catch(()=>{
                                    this.$message({
                                        message: '请求失败！',
                                        type: 'success'
                                    });
                                    this.dialogVisible = false
                                    this.loading=false
                                })
                            }else {
                                updateTemplate(this.templateForm.templateId,this.templateForm.templateName,this.templateForm.voiceType,this.templateForm.shopId,this.templateForm.remarks,this.$cookies.get("access_token")).then((result)=>{
                                    if(result["data"].code==0){
                                        this.$message({
                                            message: '修改成功！',
                                            type: 'success'
                                        });

                                        this.GetTemplateList();
                                    }else {
                                        this.$message({
                                            message: result["data"].message,
                                            type: 'error'
                                        });
                                        this.loading=false
                                    }
                                    this.dialogVisible = false
                                })
                            }
                        }
                    })

            },
            //    分页
            changePage(num){
                this.page=num;
                this.GetTemplateList()
            },


        },
        created() {  /*生命周期函数*/
            this.GetTemplateList();
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
    }

    .el-input {
        width: 200px;
        margin: 0 10px;
    }

    .el-button {
        padding: 10px 20px;
    }

    .wrapper {
        margin-top: 20px;
        text-align: center;
    }

    .operate span {
        color: #276EF1;
        margin: 0 5px;
    }

    ::v-deep .el-table th, ::v-deep .el-table td {
        text-align: center !important;
    }

    ::v-deep .el-form-item__label {
        width: 100px
    }
    ::v-deep .el-dialog__header {
        border-bottom:1px solid #ddd;
    }
    ::v-deep .el-radio__label {
        padding-left:5px;
    }

</style>
